import React from 'react';

import Logotype from 'public/assets/logotype/logotype.svg';

import styles from './FooterBlippInfo.module.scss';

export const FooterBlippInfo: React.FC = () => (
  <>
    <Logotype className={styles.blippLogotype} />
    <p className={styles.address}>
      Blipp AB, Erik Dahlbergsallén 15
      <br />
      115 20 Stockholm
      <br />
      <br />
      Besöksadress:
      <br />
      Svanvik Sörgården 1
      <br />
      546 95 Karlsborg
    </p>
  </>
);
